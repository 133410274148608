<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 2 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Select Timesheet Period",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.pendingTimesheetAuthorization();
  },
  data() {
    return {
      title: "Select Timesheet Period",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Select Timesheet Period",
          active: true,
        },
      ],
      timesheetDate: null,
      pendingLeaves: [],
      pendingLeave: null,
      pendingAuthCounter: 0,
    };
  },
  methods: {

    pendingTimesheetAuthorization(){

     let  url = `${this.ROUTES.timeAllocation}/authorization/${this.getEmployee.emp_id}`;
      this.apiGet(url, "Get Authorizations Error").then((res) => {
        const { data } = res.data;
        data.forEach((time) => {
          //console.log(time)
          let timeStatus = time?.ta_status || 0;
          if(parseInt(timeStatus) === 0){
            this.pendingAuthCounter++;
            console.log('Status: '+timeStatus)
          }
        });
        if(this.pendingAuthCounter > 0){
          alert("You have pending time sheet authorization. Kindly attend to them before proceeding.");
          this.redirectTo = `${window.location.origin}/time-sheet-authorization`
          location.href = this.redirectTo;
        }
        this.timeAuth = this.applications.length;
      });
    },
    generateTimesheet() {
      if (!this.timesheetDate) {
        this.$bvToast.toast(`Please fill in all fields correctly`, {
          title: `Payroll Month & Year is Required`,
          toaster: "b-toaster-top-right",
          appendToast: true,
          variant: "warning",
        });
      } else {
        const pym_year = this.timesheetDate.split("-")[0];
        const pym_month = this.timesheetDate.split("-")[1];
        const empId = this.getEmployee.emp_id;
        const url = `${this.ROUTES.salary}/pull-salary-routine/${empId}`;
        this.apiPost(
          url,
          { pym_month, pym_year },
          "Get Salary Routine Error"
        ).then((res) => {
          const { data } = res;
          if (data) {
            if (typeof data === "string") {
              this.$bvToast.toast(`${data}`, {
                title: `Timesheet Error`,
                toaster: "b-toaster-top-right",
                appendToast: true,
                variant: "warning",
              });
            } else {
              this.$router.push({
                name: "timesheet",
                params: { payrollMY: this.timesheetDate },
              });
            }
          }
        });
      }
    },
    getDatesInRange(startDate, endDate) {
      const date = new Date(startDate.getTime());

      const dates = [];

      while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    async checkPendingLeaves() {
      const url = `${this.ROUTES.leaveApplication}/get-employee-leave/${this.getEmployee.emp_id}`;
      await this.apiGet(url, "Get Employee Leaves Error").then((res) => {
        const { data } = res.data;
        this.pendingLeaves = data.filter((leave) => {
          return leave.leapp_status === 0; // filter out the pending leaves
        });
      });
      this.pendingLeave = this.pendingLeaves.find((leave) => {
        const leaveRange = this.getDatesInRange(
          new Date(leave.leapp_start_date),
          new Date(leave.leapp_end_date)
        );
        const currentMonth =
          new Date(`${this.timesheetDate}-01`).getMonth() + 1;
        return leaveRange.find((leaveDate) => {
          return leaveDate?.getMonth() + 1 === currentMonth;
        });
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button class="btn btn-success" @click="$router.push('/timesheets')">
        <i class="mdi mdi-plus mr-2"></i>
        View Timesheets
      </b-button>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4 d-flex justify-content-between">
              <h5 class="font-size-14 mb-0">Select Period</h5>
            </div>
            <form @submit.prevent="generateTimesheet">
              <div class="form-group">
                <label for="pmy">
                  Payroll Month & Year <span class="text-danger">*</span>
                </label>
                <input
                  id="pmy"
                  v-model="timesheetDate"
                  type="month"
                  class="form-control"
                  @change="checkPendingLeaves"
                />
              </div>
              <div v-if="pendingLeave" class="alert alert-warning">
                <em class="fa fa-exclamation-circle mr-2"></em>
                Please, note that you cannot generate timesheet for this month
                since you have pending leave applications for this month. View
                your leave applications
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="$router.push('/leave-application')"
                >
                  here.
                </span>
              </div>
              <b-button
                v-if="!submitting"
                class="btn btn-success btn-block mt-4"
                type="submit"
                :disabled="pendingLeave"
              >
                Generate Timesheet
              </b-button>
              <b-button v-else class="btn btn-success btn-block mt-4" disabled>
                Generating...
              </b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
